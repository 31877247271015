import "./footer.css"

export function Footer() {

    return (
        <div>
            <footer id="contactus" className="d-flex justify-content-between my-5 ">
                <div className="d-flex flex-column me-3">
                    <span className="logo mb-3 audio-wide">Flair Dex</span>
                </div>
                <ul className="list-group">
                    <li className="list-group-item"><a className="nav-link" target="_blank" rel="noreferrer"
                        href="https://twitter.com/FlairDefi">Twitter</a></li>
                    <li className="list-group-item"><a className="nav-link" target="_blank" rel="noreferrer"
                        href="https://discord.gg/tyV7mwXX">Discord</a></li>
                    <li className="list-group-item"><a className="nav-link" target="_blank" rel="noreferrer"
                        href="https://medium.com/@flairdefi">Medium</a>
                    </li>
                </ul>
                <ul className="list-group">
                    <li className="list-group-item"><a className="nav-link"
                        href="https://docs.flairdex.xyz/info/initial-tokenomics"
                        target="_blank" rel="noreferrer">Tokenomics</a></li>
                </ul>
                <ul className="list-group">
                    <li className="list-group-item"><a className="nav-link" href="https://docs.flairdex.xyz/"
                        target="_blank" rel="noreferrer">Docs</a></li>
                </ul>
            </footer>
            <p className="copyright">&copy; Flair Dex, All Rights Reserved</p>
        </div>
    )
}
