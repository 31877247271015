import React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";

ReactDOM.render(<BrowserRouter>
  <App />
</BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
