import React from "react";
import Layout from "./views/layout";
import NotFound from "./views/404";
import { Navigate } from "react-router-dom";

const BridgeOne = React.lazy(() => import("./views/router-protocol"));
const BridgeTwo = React.lazy(() => import("./views/synapse-protocol"));

let routes = [
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: (
                    <Navigate to="/router-protocol" replace={true} />
                ),
            },
            {
                path: "/router-protocol",
                element: (
                    <React.Suspense fallback={<>...</>}>
                        <BridgeOne />
                    </React.Suspense>
                ),
            },
            {
                path: "/synapse-protocol",
                element: (
                    <React.Suspense fallback={<>...</>}>
                        <BridgeTwo />
                    </React.Suspense>
                ),
            },
            { path: "*", element: <NotFound /> },
        ],
    },
];
export default routes;
