import routes from "./Routes";

import { useRoutes } from "react-router-dom";
import * as React from "react";

function App() {
  let element = useRoutes(routes);
  return <>{element}</>;
}

export default App;
