import * as React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
// import Logo from "../../assets/img/logo/logo.png";
import "./header.css";

function CustomLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div>
      <Link className={`nav-link ${match && "active"}`} to={to} {...props}>
        {children}
      </Link>
    </div>
  );
}

export const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <span className="logo mb-3 audio-wide">Flair Dex</span>
        </a>
        <span></span>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex justify-content-center">
            <li className="nav-item me-4" key={1}>
              <CustomLink to="/router-protocol">Router Protocol</CustomLink>
            </li>
            <li className="nav-item me-4" key={2}>
              <CustomLink to="/synapse-protocol">Synapse Protocol</CustomLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
